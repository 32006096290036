<template>
  <div id="Orden">
    <BaseBackBar titulo="Cotizaciones" goTo="/" />
    <BarraBusqueda />

    <b-overlay :show="loading || cargando" rounded="sm">
      <b-table-simple small hover>
        <b-thead>
          <b-tr>
            <b-th class="text-center">Fecha y hora</b-th>
            <b-th class="text-center">Cotización</b-th>
            <b-th>Nombre</b-th>
            <b-th>Total</b-th>
            <b-th>Proveedor</b-th>
            <b-th class="text-center">Creado por</b-th>
            <b-th class="text-center">Opciones</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr :style="`background-color: ${cotizacion.color} ;`" v-for="(cotizacion, index) of listaCotizaciones"
            :key="index">
            <b-td class="text-center">{{ cotizacion.fecha }}</b-td>
            <b-td class="text-center">{{ cotizacion.codigo }}</b-td>
            <b-td>{{ cotizacion.nombre_cliente }}</b-td>
            <b-td class="text-right">${{ parseFloat(cotizacion.total).toFixed(2) }}</b-td>
            <b-td>{{ cotizacion.nombre_proveedor }}</b-td>
            <b-td>{{ cotizacion.nombre_vendedor }}</b-td>
            <b-td class="text-center">
              <b-button variant="info" @click="onRowSelected(cotizacion)">
                Ver
              </b-button>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-overlay>

    <Detalles :cotizacion="cotizacion" :loading="loading" @restart="onRowSelected"
      @loading="value => loading = value" />
    <PdfCotizaciones :cotizacion="cotizacion" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import moment from "moment";
import BarraBusqueda from "./BarraBusqueda.vue";
import Detalles from "./Detalles.vue";
import api from "../../../utils/api";
import PdfCotizaciones from "../../../components/pdf/cotizaciones.vue";
export default {
  name: "Cotizaciones",
  data() {
    return {
      cotizacion: {
        productos: []
      },
      loading: false
    }
  },
  components: {
    BarraBusqueda,
    Detalles,
    PdfCotizaciones,
  },
  mounted() {
    this.obtenerProveedores();
    this.listarClientesSelect();
  },
  methods: {
    ...mapActions({
      obtenerProveedores: "ObtenerProveedores",
    }),
    ...mapActions("clientes", ["listarClientesSelect"]),
    async onRowSelected(item) {
      this.loading = true;

      try {
        const result = await api("GET", `cotizaciones/consultar-detalle/${item.cotizacion_id}`);
        this.cotizacion = {
          ...result.detallesCotizacion[0],
          creacion: moment(result.detallesCotizacion[0].fecha).format('DD/MM/YYYY'),
          FechaActualizacion: moment(result.detallesCotizacion[0].FechaActualizacion).format('DD/MM/YYYY'),
          productos: result.productos.map(producto => (
            {
              producto_id: producto.producto_id,
              partida: producto.partida,
              codigo: producto.codigo,
              descripcion: producto.descripcion,
              cantidad: producto.cantidad,
              iva: producto.iva,
              precio: producto.precio_unitario,
              subtotal: producto.cantidad * producto.precio_unitario,
              total: producto.precio_total,
              ieps: producto.ieps
            }))
        }

        this.$bvModal.show("bv-modal-detalles-cotizacion");
      } catch (error) {
        this.makeToast('Cotización', 'Hubo un error al consultar la cotización.', 'danger', 3000);
      } finally {
        this.loading = false;
      }
    },
    makeToast(title = null, body = null, variant = null, duration = 1000) {
      body = body || 'Toast body content';
      this.$bvToast.toast(body, {
        title: title || 'default',
        toaster: 'b-toaster-top-center',
        variant: variant || 'default',
        autoHideDelay: duration,
        solid: true
      });
    },
    onRenderComentarios(comentarios) {
      this.comentarios = comentarios;
      this.$bvModal.show("bv-modal-comentarios-cotizacion");
    },
  },
  computed: {
    ...mapGetters(["listaProveedoresSelectMulti"]),
    ...mapState("clientes", ["listaClientesSelect"]),
    ...mapGetters("cotizaciones", [
      "cargando",
      "bloqueado",
      "error",
      "listaCotizaciones",
    ]),
  },
};
</script>