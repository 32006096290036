<template>
  <b-row style="background-color: #355070;" class="p-2 align-items-center">
    <b-col sm="12" md="5">
      <b-overlay :show="loading" rounded="sm" v-if="!soyCliente">
        <BaseSelectCliente style="margin: 0px;" class="mb-2" @select-cliente="selectCliente" />
      </b-overlay>
      <b-overlay :show="cargando" rounded="sm">
        <multiselect v-model="selected" deselect-label="Can't remove this value" track-by="name" label="name"
          placeholder="Seleccione un proveedor." :multiple="true" :options="proveedoresOptions" :searchable="true"
          :allow-empty="true">
          <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.name }}</strong></template>
        </multiselect>
      </b-overlay>
    </b-col>
    <b-col sm="12" md="5">
      <b-form-input @keyup.enter="buscarProductos" id="input-query-codigo" v-model="queryCodigo" type="search"
        placeholder="Codigo" class="mb-2" />
      <b-form-input @keyup.enter="buscarProductos" id="input-query" v-model="queryCodigoInput" type="search"
        placeholder="Buscar..." />
    </b-col>
    <b-col sm="12" md="2">
      <section class="d-flex justify-content-between">
        <b-button v-b-popover.hover.top="'Articulo Sobre Pedido'" @click="$emit('filtrar', 'pedido')" style="background-color: #ffd670;" />
        <b-button v-b-popover.hover.top="'Artículo Nuevo'" @click="$emit('filtrar', 'nuevo')" style="background-color: #b0d9ff;" />
        <b-button v-b-popover.hover.top="'Precio Actualizado'" @click="$emit('filtrar', 'actualizado')" style="background-color: #aad576;" />
        <b-button v-b-popover.hover.top="'Articulo en Oferta'" @click="$emit('filtrar', 'oferta')" style="background-color: #ff474e;" />
        <b-button v-b-popover.hover.top="'Precio Especial Exclusivo'" @click="$emit('filtrar', 'exclusivo')" style="background-color: #ffc6d9;" />
      </section>
      <div>
        <b-overlay :show="loading" rounded="sm" class="mb-2">
          <b-button @click="buscarProductos" block variant="outline-light">Buscar</b-button>
        </b-overlay>
        <b-overlay :show="loading" rounded="sm">
          <b-button @click="agregarProductos" variant="outline-light" block>Agregar</b-button>
        </b-overlay>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
import { axiosInstance } from "../../../utils/axiosInstance";
export default {
  name: "BarraBusqueda",
  components: {
    Multiselect,
  },
  emits: ['filtrar'],
  data() {
    return {
      selected: [],
      queryCodigo: "",
      queryCodigoInput: "",
      cargando: false,
      proveedoresOptions: [],
    };
  },
  mounted() {
    if (this.soyCliente) {
      this.selectCliente(sessionStorage.getItem('usuario_id'));
    }
    else {
      this.buscarProveedores();
    }
  },
  methods: {
    ...mapActions({
      obtenerProveedores: "ObtenerProveedores",
      obtenerProveedoresPrecios: "ObtenerProveedoresPrecio",
    }),
    ...mapActions("productosCliente", [
      "inicializarOrden",
      "listarCarrito",
      "setProveedor",
      "setQuery",
    ]),
    makeToast(title = null, body = null, variant = null, duration = 1000) {
      body = body || 'Toast body content';
      this.$bvToast.toast(body, {
        title: title || 'default',
        toaster: 'b-toaster-top-center',
        variant: variant || 'default',
        autoHideDelay: duration,
        solid: true
      });
    },
    selectCliente(cliente) {
      this.setProveedor([]);
      this.selected = [];
      this.buscarProveedores(cliente)
      this.$emit("clienteSeleccionado", cliente);
    },
    buscarProductos() {
      this.setQuery({
        queryCodigoInput: this.queryCodigoInput,
        queryCodigo: this.queryCodigo,
      });
      this.$emit("buscarProductos");
    },
    agregarProductos() {
      this.$emit("agregarProductos");
    },
    async buscarProveedores(clienteID) {
      this.cargando = true;

      if (this.soyCliente || clienteID) {
        this.proveedoresOptions = [];
        try {
          const { data } = await axiosInstance.get('proveedores/cliente', {
            params: { usuario_id: clienteID || sessionStorage.getItem('usuario_id') },
            headers: {
              authorization: localStorage.getItem('token'),
            }
          });

          data.forEach(usuario_proveedor => {
            this.proveedoresOptions.push({
              name: usuario_proveedor.proveedor.nombre,
              value: usuario_proveedor.proveedor_id
            });
          });
        } catch (error) {
          this.makeToast('Consultar proveedores', 'Hubo un error al consultar a los proveedores', 'danger', 3000);
        }
      }
      else {
        this.proveedoresOptions = [];
        try {
          const { data } = await axiosInstance.get('proveedores/obtener-proveedores', {
            headers: {
              authorization: localStorage.getItem('token'),
            }
          });

          data.proveedores.forEach(proveedor => {
            this.proveedoresOptions.push({
              value: proveedor.proveedor_id,
              name: proveedor.nombre
            });
          });
        } catch (error) {
          this.makeToast('Consultar proveedores', 'Hubo un error al consultar a los proveedores', 'danger', 3000);
        }
      }

      this.cargando = false;
    },
  },
  computed: {
    ...mapGetters([
      "listaProveedoresSelect",
      "listaProveedoresSelectMulti",
      "listaProveedoresPrecios",
      'loading'
    ]),
    soyCliente() {
      return sessionStorage.getItem('admin') === 'false';
    },
  },
  watch: {
    selected() {
      this.setProveedor(this.selected);
    },
  },
};
</script>