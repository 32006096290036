<template>
  <sidebar-menu :collapsed="true" :menu="menu" theme="white-theme" @item-click="onItemClick">
    <span slot="toggle-icon">
      <b-icon icon="arrow-bar-right"></b-icon>
    </span>
  </sidebar-menu>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { SidebarMenu } from "vue-sidebar-menu";
import { axiosInstance } from "../utils/axiosInstance";
import { AccessCodes } from "../enums";

export default {
  data() {
    return {
      menuAdmin: [
        {
          header: true,
          title: "MENU",
          hiddenOnCollapse: true,
        },
        {
          href: "/app/catalogos",
          title: "Catalogos",
          icon: "fas fa-archive",
          child: [
            {
              href: "/app/catalogos/proveedores",
              title: "Proveedores",
              icon: "fas fa-shopping-cart",
            },
            {
              href: "/app/catalogos/productos",
              title: "Productos",
              icon: "fas fa-shopping-basket",
              child: [
                {
                  href: "/app/catalogos/producto",
                  title: "ABC Productos",
                  disabled: false,
                },
                {
                  href: "/app/catalogos/modificador-precios",
                  title: "Modificador Precios",
                  disabled: false,
                },
                {
                  href: "/app/catalogos/productos-suspendidos",
                  title: "Productos Suspendidos",
                  disabled: false,
                },
              ],
            },
            {
              title: "Usuarios",
              href: "/app/catalogos/personal",
              icon: "fas fa-user-tie",
              disabled: false,
            },
            {
              title: "Clientes",
              href: "/app/catalogos/clientes",
              icon: "fas fa-user",
              disabled: false,
            },
            {
              href: "/app/catalogos/roles",
              title: "Roles",
              icon: "fas fa-address-card",
            },
            {
              href: "/app/catalogos/transportes",
              title: "Transportes",
              icon: "fas fa-truck-moving",
            },
            {
              href: "/app/catalogos/presentaciones",
              title: "Presentaciones",
              icon: "fas fa-list-alt",
            },
            {
              href: "/app/catalogos/marcas",
              title: "Marcas",
              icon: "fas fa-shopping-bag",
            },
            {
              href: "/app/catalogos/formas-pago",
              title: "Formas de pago",
              icon: "fas fa-money-bill",
            },
            {
              href: "/app/catalogos/uso-cfdi",
              title: "Uso de CFDI",
              icon: "fas fa-newspaper",
            },
          ],
        },
        {
          href: "/app/pedidos",
          title: "Pedidos",
          disabled: false,
          icon: "fas fa-dolly",
        },
        {
          href: "/app/backorders",
          title: "Backorders",
          disabled: false,
          icon: "fas fa-dolly",
        },
        {
          href: '/app/pedidos-cancelacion',
          title: 'Pedidos en cancelación',
          disabled: false,
          icon: 'fas fa-ban'
        },
        {
          href: "/app/cotizaciones",
          title: "Cotizaciones",
          disabled: false,
          icon: "fas fa-file-invoice",
        },
        {
          href: "/app/facturas",
          title: "Facturas",
          disabled: false,
          icon: "fas fa-file",
        },
        {
          href: "/app/ofertas",
          title: "Ofertas",
          disabled: false,
          icon: "fas fa-percentage",
        },
        {
          href: "/app/precios-especiales",
          title: "Precios Especiales",
          disabled: false,
          icon: "fas fa-money-bill",
        },
        {
          href: '/app/listas-precio',
          title: 'Listas de precios',
          disabled: false,
          icon: "fas fa-list-ol",
        },
        {
          href: '/app/subcuentas',
          title: 'Subcuentas de clientes',
          disabled: false,
          icon: 'fas fa-users'
        },
        {
          href: "/app/administracion/logs-usuarios",
          title: "Registro de actividad",
          disabled: false,
          icon: "fas fa-eye",
        },
        {
          title: "Salir",
          icon: "fas fa-sign-out-alt",
        },
      ],
      menu: [
        {
          header: true,
          title: "MENU",
          hiddenOnCollapse: true,
        },
        {
          href: "/app/client/productos-cliente",
          title: "Productos",
          icon: "fas fa-shopping-cart",
        },
        {
          href: "/app/client/pedidos",
          title: "Pedidos",
          disabled: false,
          icon: "fas fa-dolly",
        },
        {
          href: '/app/client/backorders',
          title: 'Backorders',
          disabled: false,
          icon: "fas fa-dolly",
        },
        {
          href: "/app/client/cotizaciones",
          title: "Cotizaciones",
          disabled: false,
          icon: "fas fa-file-invoice",
        },
        {
          href: '/app/client/facturas',
          title: 'Estados de cuenta',
          disabled: false,
          icon: "fas fa-file",
        },
        {
          href: '/app/client/listas-precio',
          title: 'Listas de precios',
          disabled: false,
          icon: "fas fa-list-ol",
        },
        {
          href: '/app/client/subcuentas',
          title: 'Subcuentas',
          disabled: false,
          icon: 'fas fa-users'
        },
        {
          href: '/app/client/change-password',
          title: 'Cambiar contraseña',
          disabled: false,
          icon: "fas fa-wrench",
        },
        {
          title: "Salir",
          icon: "fas fa-sign-out-alt",
        },
      ],
    };
  },
  components: {
    SidebarMenu,
  },
  computed: {
    ...mapGetters(["menuAbierto"]),
  },
  async mounted() {
    if (JSON.parse(sessionStorage.getItem("admin"))) {
      this.menu = [...this.menuAdmin];
    }
    else if (sessionStorage.getItem('subcuenta') === 'true') {
      this.menu = this.menu.filter(menu => menu.title != 'Subcuentas')
      try {
        const accesos = await axiosInstance.get(`usuarios/accesos/${sessionStorage.getItem('usuario_id')}`, {
          headers: {
            authorization: localStorage.getItem('token')
          }
        });

        if (!accesos.data.find(x => x.AccesoCode === AccessCodes.ESTADOS_CUENTA)) {
          this.menu = this.menu.filter(menu => menu.title != 'Estados de cuenta')
        }

        if (!accesos.data.find(x => x.AccesoCode === AccessCodes.LISTAS_PRECIO)) {
          this.menu = this.menu.filter(menu => menu.title != 'Listas de precios')
        }
      } catch (error) {
        console.log(error);
      }
    }
  },
  methods: {
    ...mapActions({
      CambiarEstadoMenu: "CambiarEstadoMenu",
    }),
    clickMenu() {
      this.CambiarEstadoMenu();
    },
    onItemClick(event, item) {
      if (item.title == "Salir") {
        alert("Cerrando sesion");
        sessionStorage.clear();
        localStorage.clear();
        this.$router.push("/login");
      }
    },
  },
};
</script>