<template>
  <div id="ModificadorPreciosView">
    <h2>Modificador de precios</h2>
    <div class="modificador-precios-form">
      <b-row>
        <b-col>
          <label>Lista de precios:</label>
        </b-col>
        <b-col>
          <label>Proveedores</label>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-file id="form-file" v-model="listaPreciosFile" :state="Boolean(listaPreciosFile)"
            placeholder="Seleccione o arrastre un archivo CSV..." drop-placeholder="Arrastre el archivo aqui..." />
        </b-col>
        <b-col>
          <b-form-select id="proveedor-select" v-model="proveedor_id" :options="listaProveedoresSelect" />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label>Cabecera codigo</label>
          <b-form-input v-model="codigo" id="codigo" type="text" placeholder="Código"></b-form-input>
        </b-col>
        <b-col>
          <label>Cabecera descripcion</label>
          <b-form-input v-model="descripcion" id="descripcion" type="text" placeholder="Descripción"></b-form-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label>Cabecera precio</label>
          <b-form-input v-model="precio" id="precio" type="text" placeholder="Precio"></b-form-input>
        </b-col>
        <b-col>
          <b-button @click="modificarPrecios" :disabled="disabled">Modificar Precios <b-spinner v-if="disabled" small />
          </b-button>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import api from "../../../../utils/api";
export default {
  name: "ModificadorPrecios",
  data() {
    return {
      listaPreciosFile: null,
      proveedor_id: null,
      precio: "",
      codigo: "",
      descripcion: "",
      disabled: false,
    };
  },
  methods: {
    ...mapActions({
      obtenerProveedores: "ObtenerProveedores",
    }),
    modificarPrecios() {
      this.disabled = true;
      const listaPrecios = new FormData();
      listaPrecios.append("listaPrecios", this.listaPreciosFile);
      api(
        "POST",
        `modificado-precios-xlsx/modificar/${this.proveedor_id}/${this.codigo}/${this.descripcion}/${this.precio}`,
        listaPrecios,
        {
          headers: {
            "Content-type": "multipart/form-data",
            authorization: localStorage.getItem('token')
          },
        }
      )
        .then((result) => {
          this.disabled = false;
          if (result.errorCode) {
            this.$notify({
              group: "foo",
              title: "Error al guardar la imagen",
              text: "No has subido ningun archivo o usaste un archivo incorrecto",
              type: "error",
            });
          } else {
            var fileURL = window.URL.createObjectURL(new Blob([result]));
            var fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute("download", "file.csv");
            document.body.appendChild(fileLink);

            fileLink.click();
            this.$notify({
              group: "foo",
              title: "",
              text: "",
              type: "success",
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.disabled = false;
          this.$notify({
            group: "foo",
            title: "Error al guardar la imagen",
            text: "Error del servidor",
            type: "error",
          });
        });
    },
  },
  mounted() {
    this.obtenerProveedores();
  },
  computed: {
    ...mapGetters(["listaProveedoresSelect"]),
  },
};
</script>
<style lang="scss">
@import "../../../../styles/views/ModificadorPrecios.scss";

#ModificadorPreciosView {
  label {
    margin-bottom: 5px !important;
    font-weight: 600;
  }

  button {
    margin-top: 28px;
    width: 100%;
  }

  select {
    max-width: unset;
    width: 100%;
  }
}
</style>