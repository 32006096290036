<template>
  <div class="BarraBusqueda">
    <section class="seccion-superior">
      <b-form-input id="input-no-pedido" type="number" v-model="form.codigo" placeholder="No. Cotización" />
      <BaseSelectCliente ref="BaseSelectCliente" v-if="soyAdmin" @select-cliente="(id) => form.cliente = id" />
      <span class="label-fechas"> Rango de fechas: </span>
      <b-form-input id="input-fecha-inicial" type="date" v-model="form.fechaInicial" />
      <b-form-input id="input-fecha-final" type="date" v-model="form.fechaFinal" />
      <BaseButton title="Excel" />
      <BaseButton title="Buscar" @onCta="consultarCotizaciones(form)" />
    </section>
    <section class="seccion-inferior">
      <multiselect style="width: 100%" v-model="form.proveedoresSeleccionados"
        deselect-label="Este elemento no se puede eliminar" track-by="name" label="name"
        placeholder="Seleccione un proveedor." :multiple="true" :options="soyAdmin ? listaProveedoresSelectMulti : proveedores"
        :searchable="true" :allow-empty="true">
        <template slot="singleLabel" slot-scope="{ option }">
          <strong>{{ option.name }}</strong>
        </template>
      </multiselect>
      <!-- TODO filtrados por rango de fecha -->
      <BaseButton title="Hoy" @onCta="consultarCotizaciones({ ...form, rango: 'dia' })" />
      <BaseButton title="Semana" @onCta="consultarCotizaciones({ ...form, rango: 'semana' })" />
      <BaseButton title="Mes" @onCta="consultarCotizaciones({ ...form, rango: 'mes' })" />
    </section>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import Multiselect from "vue-multiselect";
import moment from "moment";
import { axiosInstance } from '../../../utils/axiosInstance'
export default {
  name: "BarraBusqueda",
  components: {
    Multiselect,
  },
  data() {
    return {
      form: {
        cliente: sessionStorage.getItem('admin') === 'true' ? null : sessionStorage.getItem('usuario_id'),
        codigo: null,
        proveedoresSeleccionados: [],
        fechaInicial: moment(new Date()).format("YYYY-MM-DD"),
        fechaFinal: moment(new Date()).format("YYYY-MM-DD"),
      },
      proveedores: [],
    };
  },
  mounted() {
    this.form.fechaFinal = moment().format("YYYY-MM-DD");
    this.form.fechaInicial = moment().format("YYYY-MM-DD");
    this.listarClientesSelect();
    this.consultarCotizaciones(this.form);
    if (this.soyAdmin) {
      this.obtenerProveedores();
    }
    else {
      this.getProveedores();
    }
  },
  destroyed() { },
  methods: {
    ...mapActions({
      obtenerProveedores: "ObtenerProveedores",
    }),
    ...mapActions("clientes", ["listarClientesSelect"]),
    ...mapActions("cotizaciones", ["consultarCotizaciones"]),
    async getProveedores() {
      try {
        const proveedores = await axiosInstance.get('proveedores/cliente', {
          params: {
            usuario_id: sessionStorage.getItem('usuario_id')
          },
          
        });

        this.proveedores = proveedores.data.map(p => ({ value: p.proveedor_id, name: p.proveedor.nombre }));
      } catch (error) {
        this.makeToast('Error', 'Hubo un error al leer los proveedores disponibles.', 'danger', 3000);
      }
    },
    makeToast(title = null, body = null, variant = null, duration = 1000) {
      body = body || 'Toast body content';
      this.$bvToast.toast(body, {
        title: title || 'default',
        toaster: 'b-toaster-top-center',
        variant: variant || 'default',
        autoHideDelay: duration,
        solid: true
      });
    },
  },
  computed: {
    ...mapGetters(["listaProveedoresSelectMulti"]),
    ...mapState("clientes", ["listaClientesSelect"]),
    soyAdmin() {
      return sessionStorage.getItem('admin') === 'true';
    }
  },
};
</script>