import Vue from "vue";
import VueRouter from "vue-router";
import AdministradorCms from "../views/admin/cms/Administrador.vue";
import Catalogos from "../views/admin/catalogos/CatalogosList.vue";
import Cotizaciones from "../views/common/cotizaciones/Cotizaciones.vue";
import FormasPago from "../views/catalogos/FormasPago.vue";
import Login from "../views/Login.vue";
import Main from "../views/Main.vue";
import MainCliente from "../views/MainCliente.vue";
import Marcas from "../views/catalogos/Marcas.vue";
import MensajesCms from "../views/admin/cms/MensajesCms.vue";
import ModificadorPrecios from "../views/admin/catalogos/productos/ModificadorPrecios.vue";
import Orden from "../views/admin/orden/Productos.vue";
import Ofertas from "../views/admin/ofertas/Ofertas.vue";
import Organizaciones from "../views/catalogos/Organizaciones.vue";
import Personal from "../views/catalogos/Personal.vue";
import PreciosClientes from "../views/catalogos/PreciosCliente.vue";
import PreciosEspeciales from "../views/admin/precios_especiales/PreciosEspeciales.vue";
import Pedidos from "../views/common/pedidos/Pedidos.vue";
import Proveedores from "../views/catalogos/proveedores/Proveedores.vue";
import Presentaciones from "../views/catalogos/Presentaciones.vue";
import ProductosSuspendidos from "../views/admin/catalogos/productos/ProductosSuspendidos.vue";
import Roles from "../views/catalogos/Roles.vue";
import Transportes from "../views/catalogos/Transportes.vue";
import TransportesProveedor from "../views/catalogos/TransportesProveedor.vue";
import UsoCfdi from "../views/catalogos/UsoCfdi.vue";
import Usuarios from "../views/admin/catalogos/usuarios/Usuarios.vue";
import { axiosInstance } from '../utils/axiosInstance';
import { AccessCodes } from '../enums/index';

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/app",
    component: Main,
    beforeEnter(to, from, next) {
      const admin = sessionStorage.getItem('admin') === 'true';

      if (admin) {
        next()
      } else {
        router.push("/app/client/productos-cliente");
      }
    },
    children: [
      {
        path: "/app/catalogos",
        name: "catalogos",
        component: Catalogos,
      },
      {
        path: "/app/catalogos/Personal",
        name: "perosnal",
        component: Personal,
      },
      {
        path: "/app/catalogos/usuarios/2",
        name: "usuarios",
        component: Usuarios,
      },
      {
        path: "/app/catalogos/roles",
        name: "roles",
        component: Roles,
      },
      {
        path: "/app/catalogos/productos",
        name: "productos",
        component: Orden,
      },
      {
        path: "/app/catalogos/marcas",
        name: "marcas",
        component: Marcas,
      },
      {
        path: "/app/catalogos/clientes",
        name: "clientes",
        component: () => import("../views/admin/catalogos/clientes/Clientes.vue"),
      },
      {
        path: "/app/precios-clientes/:id/nombre/:nombre",
        name: "precios-clientes",
        component: PreciosClientes,
      },
      {
        path: "/app/catalogos/proveedores",
        name: "proveedores",
        component: Proveedores,
      },
      {
        path: "/app/catalogos/presentaciones",
        name: "presentaciones",
        component: Presentaciones,
      },
      {
        path: "/app/catalogos/transportes",
        name: "transportes",
        component: Transportes,
      },
      {
        path: "/app/catalogos/uso-cfdi",
        name: "usoCfdi",
        component: UsoCfdi,
      },
      {
        path: "/app/catalogos/formas-pago",
        name: "FormasPago",
        component: FormasPago,
      },
      {
        path: "/app/catalogos/organizaciones",
        name: "Organizaciones",
        component: Organizaciones,
      },
      {
        path: "/app/catalogos/transporte-proveedor/:proveedor_id",
        name: "TransportesProveedor",
        component: TransportesProveedor,
      },
      {
        path: "/app/catalogos/producto",
        name: "Producto",
        component: () => import("../views/admin/catalogos/productos/AbcProductos.vue"),
      },
      {
        path: "/app/catalogos/productos-suspendidos",
        name: "ProductosSuspendidos",
        component: ProductosSuspendidos,
      },
      {
        path: "/app/catalogos/modificador-precios",
        name: "ModificadorPrecios",
        component: ModificadorPrecios,
      },
      {
        path: "/app/cms/mensajes",
        name: "MensajesCms",
        component: MensajesCms,
      },
      {
        path: "/app/cms/administrador",
        name: "AdministradorCms",
        component: AdministradorCms,
      },
      {
        path: "/app/administracion/logs-usuarios",
        name: "Logs",
        component: () => import('../views/admin/administracion/logs.vue'),
      },
      {
        path: "/app/ofertas",
        name: "Ofertas",
        component: Ofertas,
      },
      {
        path: "/app/precios-especiales",
        name: "Precios Especiales",
        component: PreciosEspeciales,
      },
      {
        path: "/app/orden",
        name: "Ordenes",
        component: Orden,
      },
      {
        path: "/app/pedidos",
        name: "Pedidos",
        component: Pedidos,
        meta: {
          backorder: false
        }
      },
      {
        path: "/app/backorders",
        name: "Backorders",
        component: () => import('../views/common/pedidos/Pedidos.vue'),
        meta: {
          backorder: true
        }
      },
      {
        path: '/app/pedidos-cancelacion',
        name: 'Cancelaciones',
        component: () => import('../views/admin/cancelaciones/PedidosCancelacion.vue'),
      },
      {
        path: "/app/facturas",
        name: "Facturas",
        component: () => import('../views/admin/facturas/Facturas.vue'),
      },
      {
        path: "/app/cotizaciones",
        name: "Cotizaciones",
        component: Cotizaciones,
      },
      {
        path: '/app/listas-precio',
        name: 'ListasPrecio',
        component: () => import('../views/cliente/ListasPrecios.vue'),
      },
      {
        path: '/app/subcuentas',
        component: () => import('../views/cliente/CuentasHijas.vue'),
      }
    ],
  },
  {
    path: "/app/client",
    component: MainCliente,
    beforeEnter(to, from, next) {
      const admin = sessionStorage.getItem('admin') === 'true';

      if (!admin) {
        next();
      } else {
        next("/login");
      }
    },
    children: [
      {
        path: "/app/client/productos-cliente",
        name: "ProductosCliente",
        component: Orden,
      },
      {
        path: '/app/client/pedidos',
        name: 'PedidosCliente',
        component: () => import('../views/common/pedidos/Pedidos.vue'),
        meta: {
          backorder: false
        }
      },
      {
        path: '/app/client/backorders',
        name: 'BackorderCliente',
        component: () => import('../views/common/pedidos/Pedidos.vue'),
        meta: {
          backorder: true
        }
      },
      {
        path: "/app/client/cotizaciones",
        name: "CotizacionesCliente",
        component: () => import('../views/common/cotizaciones/Cotizaciones.vue'),
      },
      {
        path: '/app/client/facturas',
        name: 'FacturasCliente',
        component: () => import('../views/admin/facturas/Facturas.vue'),
        beforeEnter: async (to, from, next) => {
          if (sessionStorage.getItem('subcuenta') === 'true') {
            try {
              const accesos = await axiosInstance.get(`usuarios/accesos/${sessionStorage.getItem('usuario_id')}`, {
                headers: {
                  authorization: localStorage.getItem('token')
                }
              });

              if (!accesos.data.find(x => x.AccesoCode === AccessCodes.ESTADOS_CUENTA)) {
                router.back();
              }
              else {
                next();
              }
            } catch (error) {
              router.back();
            }
          }
          else {
            next();
          }
        }
      },
      {
        path: '/app/client/listas-precio',
        name: 'ListasPrecio',
        component: () => import('../views/cliente/ListasPrecios.vue'),
        beforeEnter: async (to, from, next) => {
          if (sessionStorage.getItem('subcuenta') === 'true') {
            try {
              const accesos = await axiosInstance.get(`usuarios/accesos/${sessionStorage.getItem('usuario_id')}`, {
                headers: {
                  authorization: localStorage.getItem('token')
                }
              });

              if (!accesos.data.find(x => x.AccesoCode === AccessCodes.LISTAS_PRECIO)) {
                router.back();
              }
              else {
                next();
              }
            } catch (error) {
              router.back();
            }
          }
          else {
            next();
          }
        }
      },
      {
        path: '/app/client/change-password',
        name: 'CambiarPassword',
        component: () => import('../views/cliente/ChangePassword.vue'),
      },
      {
        path: '/app/client/subcuentas',
        name: 'CuentasHijas',
        component: () => import('../views/cliente/CuentasHijas.vue'),
        beforeEnter: (to, from, next) => {
          if (sessionStorage.getItem('subcuenta') === 'true') {
            router.back();
          }
          else {
            next();
          }
        }
      }
    ],
  },
  {
    path: '*',
    beforeEnter() {
      router.push('/app/catalogos')
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (!localStorage.getItem('token') && to.name != 'login') {
    next('/login');
  }
  else if (!sessionStorage.getItem('admin') && to.name != 'login') {
    next('/login');
  }
  else {
    next();
  }
})

export default router;
