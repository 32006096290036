<template>
  <b-modal id="bv-modal-detalles-cotizacion" data-bs-backdrop="static" data-bs-keyboard="false" size="xl" hide-footer
    hide-header ignore-enforce-focus-selector=".swal2-modal">
    <div class="Detalles">
      <section>
        <div class="text-inf">
          <span>Número de cotización: </span>
          <p>{{ cotizacion.codigo }}</p>
        </div>
        <div class="text-inf">
          <span>Fecha: </span>
          <p>{{ cotizacion.creacion }}</p>
        </div>
        <div class="text-inf" v-if="cotizacion.FechaActualizacion">
          <span>Editada: </span>
          <p>{{ cotizacion.FechaActualizacion }}</p>
        </div>
        <div class="text-inf" v-if="
          cotizacion.UsuarioActualizacion ||
          cotizacion.UsuarioActualizacionNombre
        ">
          <span>Usuario que editó: </span>
          <p>
            {{
              cotizacion.UsuarioActualizacion ||
              cotizacion.UsuarioActualizacionNombre
            }}
          </p>
        </div>
        <div class="text-inf">
          <span>Cliente:</span>
          <p>{{ cotizacion.nombre_cliente }}</p>
        </div>
        <div class="text-inf">
          <span>Porveedor:</span>
          <p>{{ cotizacion.nombre_proveedor }}</p>
        </div>
      </section>
      <section>
        <b-overlay :show="loading" rounded="sm">
          <div class="d-flex justify-content-left w-50" v-if="edicion">
            <b-form-input v-model="codigo" placeholder="Código" @keyup.enter="addProducto" />
            <b-form-input v-model="subcodigo" placeholder="Subcódigo" class="ml-2" @keyup.enter="addProducto" />
            <b-button @click="addProducto" variant="primary" class="ml-2" size="sm" style="text-wrap: nowrap">
              Agregar producto
            </b-button>
          </div>
          <b-table-simple small hover>
            <b-thead head-variant="light">
              <b-tr>
                <b-th class="text-center">Partida</b-th>
                <b-th class="text-center">Código</b-th>
                <b-th class="text-center">Descripción</b-th>
                <b-th class="text-center">Cantidad</b-th>
                <b-th class="text-center">Precio</b-th>
                <b-th class="text-center">I.V.A.</b-th>
                <b-th class="text-center">Subtotal</b-th>
                <b-th class="text-center">Total</b-th>
                <b-th v-if="edicion"></b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-for="(producto, i) of edicion
                ? newCotizacion.productos
                : cotizacion.productos" :key="i">
                <template v-if="!edicion">
                  <b-td class="text-center">{{ producto.partida }}</b-td>
                  <b-td class="text-center">{{ producto.codigo }}</b-td>
                  <b-td>{{ producto.descripcion }}</b-td>
                  <b-td class="text-center">{{ producto.cantidad }}</b-td>
                  <b-td>
                    <div class="d-flex justify-content-between">
                      <span>$</span>
                      {{ parseFloat(producto.precio).toFixed(2) }}
                    </div>
                  </b-td>
                  <b-td class="text-center">{{ producto.iva }}</b-td>
                  <b-td>
                    <div class="d-flex justify-content-between">
                      <span>$</span>
                      {{ parseFloat(producto.subtotal).toFixed(2) }}
                    </div>
                  </b-td>
                  <b-td>
                    <div class="d-flex justify-content-between">
                      <span>$</span>
                      {{ parseFloat(producto.total).toFixed(2) }}
                    </div>
                  </b-td>
                </template>
                <template v-else>
                  <b-td class="text-center">{{ producto.partida }}</b-td>
                  <b-td class="text-center">{{ producto.codigo }}</b-td>
                  <b-td>{{ producto.descripcion }}</b-td>
                  <b-td class="text-center"><input class="w-50" v-model="producto.cantidad" /></b-td>
                  <b-td>
                    <div class="d-flex justify-content-between">
                      <span>$</span>
                      {{ parseFloat(producto.precio).toFixed(2) }}
                    </div>
                  </b-td>
                  <b-td class="text-center">{{ producto.iva }}</b-td>
                  <b-td>
                    <div class="d-flex justify-content-between">
                      <span>$</span>
                      {{ calcularSubtotal(producto.precio, producto.cantidad) }}
                    </div>
                  </b-td>
                  <b-td>
                    <div class="d-flex justify-content-between">
                      <span>$</span>
                      {{
                        calcularTotal(
                          producto.precio,
                          producto.cantidad,
                          producto.iva
                        )
                      }}
                    </div>
                  </b-td>
                  <b-td class="text-center">
                    <b-button variant="outline-danger" size="sm" @click="deleteProducto(producto)">X</b-button>
                  </b-td>
                </template>
              </b-tr>
            </b-tbody>
          </b-table-simple>
          <div class="totales">
            <template v-if="!edicion">
              <div class="total-row">
                <p>Subtotal:</p>
                <p>
                  <span>$</span>
                  {{ cotizacion.subtotal }}
                </p>
              </div>
              <div class="total-row">
                <p>I.V.A.:</p>
                <p>
                  <span>$</span>
                  {{ cotizacion.iva }}
                </p>
              </div>
              <div class="total-row">
                <p>I.E.P.S.:</p>
                <p>
                  <span>$</span>
                  {{ cotizacion.ieps }}
                </p>
              </div>
              <div class="total-row">
                <p>Total:</p>
                <p>
                  <span>$</span>
                  {{ cotizacion.total }}
                </p>
              </div>
            </template>
            <template v-else>
              <div class="total-row">
                <p>Subtotal:</p>
                <p>
                  <span>$</span>
                  {{ subtotal.toFixed(2) }}
                </p>
              </div>
              <div class="total-row">
                <p>I.V.A.:</p>
                <p>
                  <span>$</span>
                  {{ iva.toFixed(2) }}
                </p>
              </div>
              <div class="total-row">
                <p>I.E.P.S.:</p>
                <p>
                  <span>$</span>
                  {{ ieps.toFixed(2) }}
                </p>
              </div>
              <div class="total-row">
                <p>Total:</p>
                <p>
                  <span>$</span>
                  {{ total.toFixed(2) }}
                </p>
              </div>
            </template>
          </div>
        </b-overlay>
      </section>
      <footer>
        <div>
          <BaseButton title="PDF" @onCta="generarPdf" v-if="!edicion" />
          <BaseButton title="Excel" @onCta="generarExcel" v-if="!edicion" />
          <BaseButton title="Crear Pedido" @onCta="crearPedido" v-if="vigente && !edicion" />
          <BaseButton :title="edicion ? 'Cancelar' : 'Edición'" @onCta="toggleEdicion" v-if="vigente"
            :disabled="loading" />
          <BaseButton title="Aceptar" @onCta="edicion ? update() : close()" :disabled="loading" />
        </div>
      </footer>
    </div>
  </b-modal>
</template>

<script>
import { jsPDF } from "jspdf";
import moment from "moment";
import Swal from "sweetalert2";
import Notification from '../../../utils/notification';
import { axiosInstance } from "../../../utils/axiosInstance";
import api from "../../../utils/api";
export default {
  name: "Detalles",
  emits: ["restart", "loading"],
  props: ["cotizacion", "loading"],
  data() {
    return {
      edicion: false,
      codigo: "",
      subcodigo: "",
      newCotizacion: {},
    };
  },
  watch: {
    cotizacion() {
      this.edicion = false;
    },
  },
  methods: {
    generarPdf() {
      let doc = new jsPDF({ unit: "px", hotfixes: ["px_scaling"] });
      doc.setFontSize(10);
      let pdfPedidosSrc = document.getElementById("pdf-cotizaciones");
      pdfPedidosSrc.style.display = "block";
      const codigo = this.cotizacion.codigo;
      doc.html(document.getElementById("pdf-cotizaciones"), {
        callback: function (doc) {
          doc.save(`Cotizacion-${codigo}-${moment().format("DD/MM/YYYY")}`);
          pdfPedidosSrc.style.display = "none";
        },
        margin: [35, 0, 100, 0],
        width: 1024,
        autoPaging: "text",
      });
    },
    async generarExcel() {
      try {
        const stream = await axiosInstance.get("cotizaciones/excel", {
          params: { cotizacion_id: this.cotizacion.cotizacion_id },
          responseType: "blob",
          headers: {
            authorization: localStorage.getItem("token"),
          },
        });

        const url = window.URL.createObjectURL(new Blob([stream.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "CotizacionJPR.xlsx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        return;
      }
    },
    async crearPedido() {
      let value = prompt(`Comentarios para pedido`);
      let comentarios = null;
      let domicilioConsignacion = "";

      if (value === null) {
        return;
      }

      const result = await Swal.fire({
        showDenyButton: true,
        showConfirmButton: true,
        showCancelButton: false,
        denyButtonText: "Sin consignación",
        confirmButtonText: "Usar consignación",
        inputLabel: "Domicilio de consignación",
        input: "textarea",
        confirmButtonColor: "#355070",
      });

      if (result.isConfirmed) {
        domicilioConsignacion = result.value;
      }

      if (domicilioConsignacion.length > 400) {
        Notification('warn', 'Domicilio consignación', 'El domicilio no debe ser mayor a 400 caracteres', 3000);
        return;
      }

      comentarios = value;

      api("post", "pedidos/crear-desde-cotizacion", {
        cotizacion_id: this.cotizacion.cotizacion_id,
        comentarios,
        domicilioConsignacion,
      })
        .then((result) => {
          if (result.errorCode) {
            this.$notify({
              group: "foo",
              title: "Error",
              text: "Error al crear el pedido",
              type: "error",
            });
            return;
          }
          this.$notify({
            group: "foo",
            title: "Pedido creado",
            text: "Puede consultar el pedido en el modulo de Pedidos",
            type: "success",
          });
        })
        .catch(() => {
          this.$notify({
            group: "foo",
            title: "Error",
            text: "Error al crear el pedido",
            type: "error",
          });
        });
    },
    makeToast(title = null, body = null, variant = null, duration = 1000) {
      body = body || "Toast body content";
      this.$bvToast.toast(body, {
        title: title || "default",
        toaster: "b-toaster-top-center",
        variant: variant || "default",
        autoHideDelay: duration,
        solid: true,
      });
    },
    calcularSubtotal(precio, cantidad) {
      if (cantidad === "") {
        return "0.00";
      }

      return (parseFloat(precio) * parseInt(cantidad)).toFixed(2);
    },
    calcularTotal(precio, cantidad, iva) {
      if (cantidad === "") {
        return "0.00";
      }

      return (
        parseFloat(precio) *
        parseInt(cantidad) *
        (parseFloat(iva) + 1)
      ).toFixed(2);
    },
    toggleEdicion() {
      this.edicion = !this.edicion;

      if (!this.edicion) {
        this.$emit("restart", this.cotizacion);
      } else {
        this.newCotizacion = { ...this.cotizacion };
      }
    },
    close() {
      this.edicion = false;
      this.$bvModal.hide("bv-modal-detalles-cotizacion");
    },
    async addProducto() {
      if (!this.codigo && !this.subcodigo) {
        this.makeToast(
          "Producto",
          "No se ha escrito un código de producto",
          "warning",
          3000
        );
        return;
      }

      if (this.newCotizacion.productos.find((p) => p.codigo === this.codigo)) {
        this.makeToast(
          "Producto",
          "Este producto ya se encuentra en la cotización",
          "warning",
          3000
        );
        return;
      }

      this.$emit("loading", true);

      try {
        const producto = await axiosInstance.get("productos", {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
          params: {
            codigo: this.codigo,
            subcodigo: this.subcodigo,
            suspendido: false,
            cliente: this.newCotizacion.usuario_id,
            proveedor: this.newCotizacion.proveedor_id,
          },
        });

        if (producto.data.productos.length === 0) {
          this.makeToast(
            "Producto",
            "No se encontró ningún producto con ese código",
            "danger",
            3000
          );
          return;
        }

        const oferta = await axiosInstance.get("ofertas", {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
          params: {
            codigo: producto.data.productos[0].codigo,
            page: 1,
            numPerPage: 10,
          },
        });

        producto.data.productos[0].tieneOferta = false;
        if (oferta.data.ofertas.length > 0) {
          producto.data.productos[0].tieneOferta = true;
          if (oferta.data.ofertas[0].tipo === "precio") {
            producto.data.productos[0].precio.precio_1 =
              oferta.data.ofertas[0].precio_1;
            producto.data.productos[0].precio.precio_2 =
              oferta.data.ofertas[0].precio_2;
            producto.data.productos[0].precio.precio_3 =
              oferta.data.ofertas[0].precio_3;
            producto.data.productos[0].precio.precio_4 =
              oferta.data.ofertas[0].precio_4;
            producto.data.productos[0].precio.precio_5 =
              oferta.data.ofertas[0].precio_5;
          } else {
            producto.data.productos[0].precio.precio_1 *=
              1 - oferta.data.ofertas[0].porcentaje / 100;
            producto.data.productos[0].precio.precio_2 *=
              1 - oferta.data.ofertas[0].porcentaje / 100;
            producto.data.productos[0].precio.precio_3 *=
              1 - oferta.data.ofertas[0].porcentaje / 100;
            producto.data.productos[0].precio.precio_4 *=
              1 - oferta.data.ofertas[0].porcentaje / 100;
            producto.data.productos[0].precio.precio_5 *=
              1 - oferta.data.ofertas[0].porcentaje / 100;
          }
        }

        let precio;

        switch (producto.data.precios.tipo) {
          case 1:
            precio = producto.data.productos[0].precio.precio_1;
            break;
          case 2:
            precio = producto.data.productos[0].precio.precio_2;
            break;
          case 3:
            precio = producto.data.productos[0].precio.precio_3;
            break;
          case 4:
            precio = producto.data.productos[0].precio.precio_4;
            break;
          case 5:
            precio = producto.data.productos[0].precio.precio_5;
            break;
        }

        this.newCotizacion.productos.push({
          ...producto.data.productos[0],
          precio,
          cantidad: 0,
          partida: this.newCotizacion.productos.length + 1,
          nuevo: true,
        });

        this.makeToast(
          "Producto",
          "Producto agregado con éxito.",
          "success",
          2000
        );
      } catch (error) {
        this.makeToast(
          "Producto",
          "Hubo un error al obtener el producto",
          "danger",
          3000
        );
      } finally {
        this.$emit("loading", false);
      }
    },
    async update() {
      const cotizacion = {
        total: this.total.toFixed(2),
        subtotal: this.subtotal.toFixed(2),
        iva: this.iva.toFixed(2),
        ieps: this.ieps.toFixed(2),
      };

      const productos = this.newCotizacion.productos
        .filter((producto) => !producto.nuevo)
        .map((producto) => ({
          producto_id: producto.producto_id,
          cantidad: producto.cantidad,
          precio_unitario: producto.precio,
          oferta: producto.tieneOferta,
          iva_total: (
            parseFloat(
              this.calcularSubtotal(producto.precio, producto.cantidad)
            ) * producto.iva
          ).toFixed(2),
          precio_total: this.calcularTotal(
            producto.precio,
            producto.cantidad,
            producto.iva
          ),
        }));

      const nuevosProductos = this.newCotizacion.productos
        .filter((producto) => producto.nuevo)
        .map((producto) => ({
          cotizacion_id: this.newCotizacion.cotizacion_id,
          producto_id: producto.producto_id,
          cantidad: producto.cantidad,
          precio_unitario: producto.precio,
          oferta: producto.tieneOferta,
          partida: producto.partida,
          iva: producto.iva,
          ieps: producto.ieps,
          iva_total: (
            parseFloat(
              this.calcularSubtotal(producto.precio, producto.cantidad)
            ) * producto.iva
          ).toFixed(2),
          precio_total: this.calcularTotal(
            producto.precio,
            producto.cantidad,
            producto.iva
          ),
        }));

      this.$emit("loading", true);
      try {
        await axiosInstance.put(
          `cotizaciones/${this.newCotizacion.cotizacion_id}`,
          {
            cotizacion,
            productos,
            nuevosProductos,
          },
          {
            headers: {
              authorization: localStorage.getItem("token"),
            },
          }
        );

        this.makeToast("Cotización", "Cotización actualizada", "success", 2000);
        this.$emit("restart", this.newCotizacion);
      } catch (error) {
        this.makeToast(
          "Cotización",
          "Hubo un error al actualizar la cotización",
          "danger",
          3000
        );
        this.$emit("loading", false);
      }
    },
    async deleteProducto(producto) {
      this.$emit("loading", true);

      try {
        await axiosInstance.delete(
          `cotizaciones/producto/${this.newCotizacion.cotizacion_id}/${producto.producto_id}`,
          {
            headers: {
              authorization: localStorage.getItem("token"),
            },
          }
        );

        this.makeToast("Producto", "Producto eliminado", "success", 2000);
        this.$emit("restart", this.newCotizacion);
        setTimeout(() => {
          this.newCotizacion = { ...this.cotizacion };
        }, 2000);
      } catch (error) {
        this.makeToast(
          "Producto",
          "Hubo un error al eliminar el producto",
          "danger",
          3000
        );
      } finally {
        this.$emit("loading", false);
      }
    },
  },
  computed: {
    vigente() {
      const diff = moment().diff(this.cotizacion.fecha, "days");
      return diff <= 8;
    },
    subtotal() {
      let subtotal = 0;

      for (const producto of this.newCotizacion.productos) {
        if (producto.cantidad === "") {
          continue;
        }

        subtotal += parseFloat(producto.precio) * parseInt(producto.cantidad);
      }

      return subtotal;
    },
    total() {
      let total = this.subtotal;

      for (const producto of this.newCotizacion.productos) {
        if (producto.cantidad === "") {
          continue;
        }
        total +=
          parseFloat(producto.precio) *
          parseInt(producto.cantidad) *
          parseFloat(producto.iva);
      }

      return total;
    },
    iva() {
      let iva = 0;

      for (const producto of this.newCotizacion.productos) {
        iva +=
          parseFloat(producto.precio) *
          parseInt(producto.cantidad) *
          parseFloat(producto.iva);
      }

      return iva;
    },
    ieps() {
      let ieps = 0;

      for (const producto of this.newCotizacion.productos) {
        ieps +=
          parseFloat(producto.precio) *
          parseInt(producto.cantidad) *
          parseFloat(producto.ieps);
      }

      return ieps;
    },
  },
};
</script>
